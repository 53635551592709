<template>
  <div class=" template-1">
    <header class="page-header container mx-md">
      <h1>Membership Renewal (E503D)</h1>
    </header>
    <div v-if="!allowPage">
      <security-banner></security-banner>
    </div>
    <section v-if="allowPage">
      <div class="container" v-if="!account.bnk_routing_number">
        <NoBankDetails customMsg="a remittance"></NoBankDetails>
      </div>

      <div v-else class="container">
        <div class="row mb-5">
          <div class="col-md-4 col-sm-4 ">
            <div class="renew-sec-heading">Select one or more members</div>
            <div v-if="membersListWithPendingDues.length === 0">No members to select.</div>
            <div v-else class="renewal-list-wrap">
              <ul>
                <li
                  v-for="member in membersListWithPendingDues"
                  :key="member.ind_account_id"
                  @click="selectMember(member)"
                >
                  {{ member.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 col-sm-8">
            <div class="renew-sec-heading">Submit multiple membership renewals from camp funds.</div>
            <div v-if="selectedMembers.length > 0">
              <div class="table-responsive">
                <table class="table b-table table-striped table-hover table-renewal-membership">
                  <thead>
                    <tr>
                      <th>Camp Member Name</th>
                      <th>Paid By</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="selected in selectedMembers" :key="selected.ind_key">
                      <td>{{ selected.name }}</td>
                      <td>
                        <b-form-group class="font-style-2 mb-0 payeeDropdown">
                          <b-form-select
                            v-model="selected.secondaryPayee"
                            :options="optionsWithPlaceholder(selected)"
                            class="g-select payeeDropdown"
                            v-b-tooltip.hover
                            :title="getSelectedPayeeTitle(selected)"
                            @change="updateSecondaryPayee(selected)"
                          ></b-form-select>
                        </b-form-group>
                      </td>
                      <td>
                        <span class="amount-display">$ {{ selected.amount }}</span>
                      </td>

                      <td>
                        <button @click="removeMember(selected)" class="btn btn-close">
                          <iClose />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td>
                        <span class="renew-tfoot-prepend">$</span><span>{{ totalAmount.toFixed(2) }}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="alert alert-info">
                <p class="mb-2 fontSizeP-15">
                  {{ MemberShipRenewalAgreementInfo.QUALIFICATION_VERIFIED }}
                </p>
                <!-- font-weight-bolder -->
                <p class="mb-2 fontSizeP-15 font-weight-bolder">
                  <i>
                    {{ MemberShipRenewalAgreementInfo.BELIEF_STATEMENT_TEXT }}
                  </i>
                </p>
                <p class="mb-2 fontSizeP-15">
                  {{ MemberShipRenewalAgreementInfo.MINISTRY_AGREEMENT_TEXT }}
                </p>
              </div>
              <div class="renew-btn-grp">
                <b-button class="btn btn-tertiary btn-w-sm" @click="clearSelection">Clear</b-button>
                <b-button
                  class="btn btn-primary btn--yellow"
                  v-if="!isTransactionPending"
                  id="addSummaryModal"
                  @click="prepareTheModal($event, 'addSummaryModal')"
                  target="_blank"
                  rel="noopener"
                  >Submit</b-button
                >
              </div>
            </div>
            <div v-else>
              <p class="noRow">
                No Members selected Yet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AddSummary
      modalTitle="- Membership Renewal"
      :i18n="translations.components"
      :translations="translations"
      :multiMemberRenewal="selectedMembers"
      :totalCalculatedAmount="Number(totalAmount.toFixed(2))"
      @continueToPayment="saveSelectedMembers($event)"
    />
    <div style="height: 40px">&nbsp;</div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import iClose from '@/assets/svgs/icon-close-red-color.vue'
import { API_CONFIG } from '@/config/api.config.js'
//Mixin
import { staffUserMixin } from '@/mixins/staffUserMixin'
import { translationMixin } from '@/mixins/translationMixin'
import { remittanceMixin } from '@/mixins/remittanceMixin'
import { modalMixin } from '@/mixins/modalMixin'
import accountMixin from '@/mixins/accountMixin'
// Components
import AddSummary from '@/components/treasury-tools/add_summary'
import SecurityBanner from '@/components/SecurityBanner'
import NoBankDetails from '@/components/NoBankDetails'
//Constants
import { MemberShipRenewalAgreementInfo } from '../../../../constants/MemberShipConstants'

export default {
  name: 'national-association-goal-planning-NAGP',
  mixins: [translationMixin, staffUserMixin, remittanceMixin, modalMixin, accountMixin],
  components: {
    securityBanner: SecurityBanner,
    iTooltip: iTooltip,
    iClose: iClose,
    AddSummary: AddSummary,
    NoBankDetails: NoBankDetails
  },
  data() {
    return {
      title: '',
      selectedMembers: [],
      secondaryPayee: null,
      secured_tile_view_controls: {
        treasury_camp_e503D_membership_renewal_tile: '57b7688b-d2c4-4530-9e5e-3673b6e4f208'
      },
      translations: {}
    }
  },
  computed: {
    ...mapGetters({
      members: 'membership/members',
      userOrgKey: 'user/userOrgKey',
      iCanSee: 'user/iCanSee',
      stateUndist: 'campModule/stateUndist',
      processTime: 'memberShipRenewalModule/processTime',
      stateOrCamp: 'campModule/stateOrCamp'
    }),
    MemberShipRenewalAgreementInfo() {
      return MemberShipRenewalAgreementInfo
    },
    membersListWithPendingDues() {
      const seen = new Set()
      return this.members.filter(member => {
        const duplicate = seen.has(member.ind_account_id)
        const hasPendingDues = member.dues_paid_flag === false
        seen.add(member.ind_account_id)
        return (
          hasPendingDues &&
          !duplicate &&
          !this.selectedMembers.some(selected => selected.ind_key === member.ind_account_id)
        )
      })
    },
    totalAmount() {
      try {
        return this.selectedMembers.reduce((total, member) => {
          const amount = parseFloat(member.amount) || 0
          return total + amount
        }, 0)
      } catch (error) {
        console.error('Error calculating total amount:', error)
        return 0
      }
    },
    allowPage() {
      return (
        this.iCanSee(this.secured_tile_view_controls.treasury_camp_e503D_membership_renewal_tile) &&
        ((!!this.stateUndist && !!this.stateUndist.cmp_org_key) || this.isStaffMember)
      )
    },
    isTransactionPending() {
      return this.remittanceTimeRemaining(this.processTime) > 0
    }
  },
  methods: {
    ...mapActions({
      loadMembersByCamp: 'membership/loadMembersByCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPendingRenewalTime: 'memberShipRenewalModule/setPendingRenewalTime'
    }),

    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getViewTranslations(),
          this.getComponentTranslations('security-banner', 'add-summary')
        ]).then(results => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        })
      } catch (e) {
        console.error(e)
      } finally {
        if (this.remittanceTimeRemaining(this.processTime) > 0) {
          this.setLoadingStatus(false)
          await this.showRenewalAPIInProcessMessage()
        } else {
          await this.loadMembersByCamp(this.userOrgKey)
          this.setLoadingStatus(false)
        }
      }
    },

    updateSecondaryPayee(selected) {
      if (selected.secondaryPayee === selected.cmp_number) {
        selected.secondaryPayeeName = 'Camp'
      } else {
        const selectedOption = this.filteredMembers(selected.ind_key).find(
          member => member.value === selected.secondaryPayee
        )
        selected.secondaryPayeeName = selectedOption ? selectedOption.text : 'Self'
      }
    },

    selectMember(member) {
      if (this.selectedMembers.length < MemberShipRenewalAgreementInfo.MAX_Member_Limit) {
        const exists = this.selectedMembers.find(selected => selected.ind_key === member.ind_account_id)
        if (!exists) {
          this.selectedMembers.push({
            cmp_number: member.cmp_number,
            ind_key: member.ind_account_id,
            name: member.name,
            amount: member.member_renewal_amount,
            secondaryPayee: member.ind_account_id,
            secondaryPayeeName: 'Self'
          })
        }
      } else {
        Vue.swal({
          title: `Max limit of member selection reached. Max number of member selection is ${MemberShipRenewalAgreementInfo.MAX_Member_Limit}`,
          toast: true,
          position: 'top',
          showConfirmButton: false,
          padding: '12px',
          timer: 10000,
          grow: 'row',
          icon: 'error',
          timerProgressBar: true,
          width: '400px'
        })
      }
    },
    removeMember(selected) {
      const index = this.selectedMembers.findIndex(
        selectedMembersList => selectedMembersList.ind_key === selected.ind_key
      )
      if (index !== -1) {
        this.selectedMembers.splice(index, 1)
      }
    },
    //For removing duplicate account from the second dropdown i.e Payed by dropdown
    filteredMembers(selectedPayedByKey) {
      return this.members
        .filter(member => member.ind_account_id !== selectedPayedByKey)
        .map(member => ({
          value: member.ind_account_id,
          text: member.name
        }))
    },
    clearSelection() {
      this.selectedMembers = []
      this.scrollUpToTop()
    },
    optionsWithPlaceholder(selectedMember) {
      return [
        { value: selectedMember.ind_key, text: 'Self', disabled: true },
        { value: selectedMember.cmp_number, text: 'Camp' },
        ...this.filteredMembers(selectedMember.ind_key)
      ]
    },
    getSelectedPayeeTitle(member) {
      const selectedPayee = member.secondaryPayee
      if (selectedPayee === member.ind_key) return ''
      const selectedOption = this.filteredMembers(member.ind_account_id).find(member => member.value === selectedPayee)
      return selectedOption ? selectedOption.text : ''
    },
    membershipRenewalApiCall(parms) {
      const route = API_CONFIG.processMembershipRenewal()
      return axios.post(route, parms)
    },
    async showRenewalAPIInProcessMessage() {
      let timeRemaining = this.remittanceTimeRemaining(this.processTime)
      this.$swal({
        icon: 'error',
        text: `There is a transaction in progress. Approximate wait time to submit another members list for renewal is ${timeRemaining} minute${
          timeRemaining > 1 ? 's' : ''
        }`,
        confirmButtonText: 'Okay'
      }).then(async () => {
        this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}/menu` })
      })
    },

    //Modal For showing that Payment Process has been initaiated
    async paymentProcessInitiatedModal(jsonForMembershipPayment) {
      this.setPendingRenewalTime()
      this.membershipRenewalApiCall(jsonForMembershipPayment)
      this.$swal({
        icon: 'success',
        text:
          'Your transaction is processing and may require up to 5 minutes to complete. Once completed you will receive an email confirming the transaction.',
        confirmButtonText: 'Ok'
      }).then(() => {
        this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}/menu` })
      })
    },

    scrollUpToTop() {
      const elmnt = document.getElementById('nav')
      elmnt.scrollIntoView()
    },

    prepareTheModal(evt) {
      this.scrollUpToTop()
      this.showModal(evt, 'addSummaryModal', 'summaryModal')
    },

    async saveSelectedMembers() {
      const jsonForMembershipPayment = {
        campId: this.selectedMembers[0].cmp_number,
        contributions: []
      }
      this.selectedMembers.forEach(selectedMember => {
        const contribution = {
          memberID: selectedMember.ind_key,
          paidBy: selectedMember.secondaryPayee ? selectedMember.secondaryPayee : null
        }
        jsonForMembershipPayment.contributions.push(contribution)
      })
      try {
        this.paymentProcessInitiatedModal(jsonForMembershipPayment)
      } catch (error) {
        console.error('Error during membership renewal:', error)
      }
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await this.getAccount(this.homeCamp.key)
      if (!!this.account.bnk_routing_number) {
        await this.page_load()
      }
    } catch (error) {
      console.error('Error during account retrieval or page load:', error)
    } finally {
      this.setLoadingStatus(false)
    }
  }
}
</script>

<style lang="scss">
.renewal-list-wrap {
  background: #fff;
  padding: 10px 20px;
  max-height: 300px;
  overflow-y: scroll;
  ul {
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 5px;
      list-style: none;
      a {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.fontSizeP-15 {
  font-size: 15px;
}
/* Optional: To add hover effects, such as a background color change */
.renewal-list-wrap:hover {
  cursor: pointer; /* Change background color on hover */
}

.payeeDropdown:hover {
  cursor: pointer; /* Change background color on hover */
}

.noRow {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1rem;
}

.renew-btn-grp {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  .btn {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 12px 35px;
    border-radius: 0;
    &.btn--yellow {
      background: #fbc301;
      border-color: #fbc301;
      color: #fff;
      margin-left: 25px;
      &:hover {
        background: #003946;
        border-color: #003946;
      }
    }
  }
}
.renew-sec-heading {
  font-size: 34px;
  letter-spacing: 0px;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
}
.renewal-input-grp {
  background: #fff;
  max-width: 230px;
}
.main-heading {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .badge {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
  }
}
.table-renewal-membership {
  tfoot {
    border-top: 5px double #747474;
    tr {
      border: none;
    }
    td {
      border: none;
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;
      color: #212529;
      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: left;
      }
      .renew-tfoot-prepend {
        display: inline-flex;
        margin-right: 5px;
      }
    }
  }
  th {
    background-color: #003b48;
    color: #fff;

    &:first-child {
      width: 220px;
      div {
        display: none;
      }
    }
  }

  td {
    border-top: none;
    vertical-align: middle;
    &:first-child {
      width: 220px;
      text-align: left;
    }
    &:nth-child(2) {
      width: 240px;
    }
  }
}
.input-group {
  margin: 0;
  width: auto;
  border-radius: 0;
  display: flex;
  flex-wrap: nowrap;

  .input-group-text {
    background: #ececec;
    color: #003946;
    border-radius: 0;
  }
}
.button-div {
  background-color: #003946;
  padding: 12px 22px;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;
}
@media only screen and (max-width: 960px) {
  .renewal-list-wrap {
    margin-bottom: 50px;
  }
  .renew-sec-heading {
    font-size: 30px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .renew-sec-heading {
    font-size: 26px;
    line-height: 35px;
  }
  .table-responsive {
    overflow-x: auto;
  }
  .table-responsive .table-renewal-membership {
    width: 500px;
  }
}
</style>
