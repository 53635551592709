import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      account: 'accountModule/currentAccount',
      homeCamp: 'campModule/homeCamp'
    })
  },
  methods: {
    ...mapActions({
      getAccount: 'accountModule/getAccount'
    })
  }
}
