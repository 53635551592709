<template>
  <div class="alert alert-warning" role="alert">
    No account found. Please add your camp's bank account information on theConnection before submitting
    {{ customMsg }}.
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'no-bank-details',
  props: {
    customMsg: {
      type: String,
      required: true
    }
  }
}
</script>
